import { FC } from 'react'
import { SectionHeadingType } from '@/types/site.interface'
import Text from '@ignition/library/components/atoms/text'
import SectionContent from './section-content'

const styles = {
  container: 'text-center mb-12',
  subHeading: 'text-lg font-heading font-semibold text-subheading mb-2 heading-style',
  heading: 'text-2xl font-heading font-bold tracking-tight text-heading sm:text-3xl mb-2 heading-style',
  summary: 'mx-auto mt-5 max-w-prose text-xl text-gray-500',
}

interface SectionHeadingProps {
  heading?: string
  headingType?: SectionHeadingType
  subheading?: string
  summary?: string
}

const SectionHeading: FC<SectionHeadingProps> = ({ heading, headingType = 'h2', subheading, summary }) => {
  const isEmpty = !heading && !subheading && !summary

  if (isEmpty) {
    return null
  }

  return (
    <SectionContent className={styles.container}>
      {subheading && <p className={styles.subHeading}>{subheading}</p>}
      {heading && (
        <Text as={headingType} className={styles.heading}>
          {heading}
        </Text>
      )}
      {summary && <p className={styles.summary}>{summary}</p>}
    </SectionContent>
  )
}

export default SectionHeading
