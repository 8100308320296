/* eslint-disable @typescript-eslint/no-explicit-any */
import get from 'just-safe-get'
import config from './public-config'

const makeConfig = (config: { [key: string]: any } = {}) => {
  return {
    get: (key: string, defaultValue?: any) => get(config, key, defaultValue),
    config,
  }
}

const publicConfig = makeConfig(config)
export const getConfigPublicValue = (key: string, defaultValue?: any) => publicConfig.get(key, defaultValue)
