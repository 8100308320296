import { getConfigPublicValue } from '@/config'
import { Job } from '@/types/site.interface'
import logger from '@ignition/library/lib/logger'

interface FetchApi {
  method: 'GET' | 'POST'
  body: { [key: string]: unknown }
  slug: string
}

export const fetchApi = ({ slug, method, body }: FetchApi) => {
  try {
    return fetch(`${getConfigPublicValue('services.recrewted.api')}/api${slug}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...body,
      }),
    })
  } catch (err) {
    logger.error(err)
  }
}

const fetchJob = async (jobId: string, customerId: string): Promise<Job> => {
  const res = await fetchApi({
    slug: '/customer/job',
    method: 'POST',
    body: {
      jobId,
      customerId,
    },
  })

  if (res?.status !== 200) {
    throw new Error(`Failed to fetch job. Status code ${res?.status}`)
  }

  return await res.json()
}

export default fetchJob
