export const twAspectRatio = (ratio?: string) => {
  switch (ratio) {
    case '1:1':
      return 'aspect-square'
    case '2:1':
      return 'aspect-2/1'
    case '3:1':
      return 'aspect-3/1'
    case '5:7':
      return 'aspect-5/7'
    case '4:6':
      return 'aspect-4/6'
    case '16:9':
      return 'aspect-video'
    default:
      return 'aspect-auto'
  }
}
