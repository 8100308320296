'use client'

import { FC, useCallback, useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { usePathname } from 'next/navigation'
import { shallow } from 'zustand/shallow'
import Section from '@/components/molecules/section'
import { Block, ButtonLink } from '@/types/site.interface'
import { trackEvent } from '@ignition/library/components/atoms/tracker'
import { JobApplyCta } from './job-apply-cta'
import useSurveyStore from './store'
import { eventCategory } from './track-event'

const SurveyModal = dynamic(() => import('./survey-modal').then((e) => e.SurveyModal), { ssr: false })

export interface JobSurveyProps {
  title?: string
  summary?: Block
  jobTitle?: string
  location?: string
  buttonTitle?: string
  privacyHref?: string
  customerId: string
  jobId: string
  buttonType: 'button' | 'anchor'
  button: ButtonLink
}

const JobSurvey: FC<JobSurveyProps> = ({ jobTitle, privacyHref, buttonType, ...props }) => {
  const pathname = usePathname()
  const [isOpen, setIsOpen] = useState(false)
  const [setSurveyState, fetchJobSurvey, reset] = useSurveyStore(
    (state) => [state.setSurveyState, state.fetchJobSurvey, state.reset],
    shallow,
  )
  // avoid been called multiples times when component is re-rendering
  const setFirstState = useCallback(() => {
    setSurveyState({
      jobId: props.jobId,
      customerId: props.customerId,
    })
  }, [props.customerId, props.jobId, setSurveyState])

  useEffect(() => {
    // CU-866aedpuy: Job survey kept the current step while we navigate to another survey
    // to avoid that, we need to reset the state when we navigate to another page.
    // it should be called only once when the component is mounted
    reset()
    setFirstState()
  }, [reset, setFirstState, pathname])

  const handleOpen = async () => {
    if (buttonType === 'anchor') {
      return
    }
    setFirstState()
    setIsOpen(true)
    await fetchJobSurvey()
    trackEvent('open', {
      category: eventCategory,
      current_step: useSurveyStore?.getState().currentStep,
    })
    return
  }

  const handleOnClose = () => {
    if (buttonType === 'anchor') return
    trackEvent('close', {
      category: eventCategory,
      current_step: useSurveyStore?.getState().currentStep,
    })
    setIsOpen(false)
    return
  }

  return (
    <Section id="job-survey-section" contentClassName="c-job-survey relative overflow-hidden bg-gray-800">
      <JobApplyCta
        privacyHref={privacyHref}
        buttonType={buttonType}
        onAction={handleOpen}
        jobTitle={jobTitle}
        {...props}
      />
      <SurveyModal open={isOpen} onClose={() => handleOnClose()} jobTitle={jobTitle} privacyHref={privacyHref} />
    </Section>
  )
}

export default JobSurvey
