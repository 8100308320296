import { FC } from 'react'

const styles = {
  overline: 'text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-2',
}

interface SectionHeadingProps {
  overline: string | undefined
}

const SectionHeading: FC<SectionHeadingProps> = ({ overline }) => {
  return <div>{overline && <h1 className={styles.overline}>{overline}</h1>}</div>
}

export default SectionHeading
