import { create } from 'zustand'
import { Job } from '@/types/site.interface'
import { setDimension } from '@ignition/library/components/atoms/tracker'
import logger from '@ignition/library/lib/logger'
import fetchJob from './fetch-job'
import { PossibleStep } from './survey-modal'

interface ErrorToast {
  message: string
  isActive: boolean
}

interface State {
  jobId?: string
  customerId?: string
  isError?: boolean
  isLoading?: boolean
  job: Job | null
  privacyHref?: string
  currentStep: PossibleStep
  errorToast?: ErrorToast
  disabledBackStep?: boolean
}

interface Actions {
  setDisabledBackStep: (payload: boolean) => void
  fetchJobSurvey: () => Promise<void>
  setSurveyState: (payload: Partial<State>) => void
  setCurrentStep: (payload: PossibleStep) => void
  setErrorToast: (payload: ErrorToast) => void
  reset: () => void
}

const initialState: State = {
  isError: false,
  isLoading: false,
  job: null,
  currentStep: 'INTRODUCTION',
  disabledBackStep: false,
  errorToast: { message: '', isActive: false },
}

const useSurveyStore = create<State & Actions>()((set, get) => ({
  ...initialState,
  fetchJobSurvey: async () => {
    const { job, isError, jobId, customerId } = get()
    if (!jobId || !customerId) {
      logger.error(`JobId or CustomerId missing: jobId=${jobId}, customerId=${customerId}`)
      set({ isError: true })
      return
    }

    set({ isLoading: true })
    if (!job || !isError) {
      try {
        const jobData = await fetchJob(jobId, customerId)
        set({ job: jobData, isLoading: false })
        setDimension({
          customer_name: jobData?.customer?.name,
          job_name: jobData?.title,
        })
        logger.info('Job fetched', jobData)
      } catch (err) {
        logger.error('Failed to fetch job', err)
        set({ isError: true, isLoading: true })
      }
    } else {
      set({ isLoading: false })
    }
  },
  setSurveyState: (payload: Partial<State>) => set(() => payload),
  setCurrentStep: (payload) => set({ currentStep: payload }),
  setDisabledBackStep: (payload) => set({ disabledBackStep: payload }),
  setErrorToast: (payload: ErrorToast) => set({ errorToast: payload }),
  reset: () => set(initialState),
}))

export default useSurveyStore
