import logger from '../../../lib/logger'
import { CustomParams, PayloadDataLayer } from './interface'

const log = (...values: unknown[]): void => logger.info('[Tracker]', ...values)

export const trackEvent = (eventName: string, { category, label, ...payload }: PayloadDataLayer) => {
  if (typeof window?.dataLayer !== 'undefined') {
    window?.dataLayer.push({
      event: eventName,
      eventCategory: category,
      eventLabel: label,
      eventCallback: () => {
        log(`Event: ${eventName}`, category, { label, ...payload })
      },
      ...payload,
    })
  }
}

export const setDimension = (state: CustomParams) => {
  if (typeof window?.dataLayer !== 'undefined') {
    window.dataLayer.push({ customerInformation: state })
    log('customerInformation', state)
  }
}
